import FormComponent from "../../Form";
import Api from "../../Api";
import labels from "../../Common/translations";
import { ROLE_PARTNER, ROLE_ROOT } from "../../../constants/roles";

export default {
  fields: {
    offline: {
      type: FormComponent.GROUP,
      fields: {
        offlinePrivilege: {
          label: labels.fields.activate_offline_mode,
          type: FormComponent.CHECKBOX,
          disabled: () => true,
          options: {
            toggle: true,
          },
        },
        offlineDuration: {
          label: labels.fields.offline_file_lifetime,
          options: {
            label: { basic: true, content: labels.units.days },
            labelPosition: "right",
          },
          disabled: () => true,
        },
      },
    },
    partnerData: {
      type: FormComponent.GROUP,
      fields: {
        partner: {
          label: labels.fields.partner,
          disabled: () => true,
          hidden: (props) => !props.data.partner || !props.data.partner.ref,
          type: FormComponent.SELECT,
          values: {
            from: "state",
            prop: "partnerValues",
            source: () =>
              Api.selectValues("companies", {
                fsort: "name",
              }),
          },
          options: {
            defaultValue: ({ authUser }) =>
              authUser.role !== ROLE_ROOT && authUser.role !== ROLE_PARTNER
                ? authUser.company
                : "",
          },
          return: FormComponent.FORMAT_OBJECT,
        },
        allowPartnerAccess: {
          hidden: (props) => !props.data.partner || !props.data.partner.ref,
          label: labels.fields.allow_partner_access,
          type: FormComponent.CHECKBOX,
        },
      },
    },
  },
  buttons: {
    delete: false,
    submit: true,
    cancel: true,
    extras: [],
  },
  actions: {
    read: (ref) => Api.instance().get(ref),
    update: ({ uid, ...data }) =>
      Api.instance().patch(`companies/${uid}`, data),
    redirect: FormComponent.REDIRECT_NONE,
  },
  extras: [],
};
